#contact {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.contactTitle {
    font-size: 3rem;
    font-weight: 600;
    padding: 1rem;
    margin-bottom: 1rem;
}

.contactDescription {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .message {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    background: rgb(40, 40, 40);
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}

.submitBtn {
    background: white;
    color: black;
    border: none;
    padding: 0.75rem 3.5rem;
    margin: 1rem;
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}

.submitBtn:hover {
    background: gold;
    color: black;
    font-weight: 500;
    cursor: pointer;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.link {
    display: flex;
    flex-wrap: wrap;
}

.linkImg {
    object-fit: cover;
    background: transparent;
    width: 3rem;
    height: 3rem;
    margin: 1rem 0.75rem;
    cursor: pointer;
}