#intro {
    height: calc(100vh - 5rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#hello{
    font-size: 1.75rem;
    font-weight: 200;
}

#introName {
    color: gold;
}

.introPara {
    font-size: medium;
    font-weight: 200;
    letter-spacing: 0.5px;
}

.resumeBtn {
    background: white;
    margin: 1rem 0;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
}

.resumeBtn:hover {
    background: gold;
    cursor: pointer;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

#resumeImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
    width: 1rem;
}

