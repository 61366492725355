#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.SkillTitle {
    font-size: 3rem;
    font-weight: 600;
    padding: 1rem;
}

.skillDescription{
    backdrop-filter: blur(50px);
    font-weight: 300;
    font-size: 1rem;
    max-width: 80%;
    padding: 1rem 2rem;
}

.skillBars {
    backdrop-filter: blur(50px);
    border: 2px solid white;
    border-radius: 20px;
    overflow: hidden;
    margin: 0.75rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.skillBar {
    display: flex;
    margin: 1rem;
    padding: 0 2rem;
}

.skillBarImg {
    object-fit: cover;
    height: 5rem;
    width: 5rem;
    margin-right: 2rem;
}

.skillBarText>p {
    font-size: 0.9rem;
    font-weight: 200;
}