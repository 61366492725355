#works {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.worksTitle {
    font-size: 3rem;
    font-weight: 600;
    padding: 1rem;
    margin-bottom: 1rem;
}

.worksHeader {
    backdrop-filter: blur(50px);
    font-weight: 300;
    font-size: 1rem;
    max-width: 80%;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
}

.worksContainer {
    backdrop-filter: blur(50px);
    border: 2px solid white;
    border-radius: 20px;
    margin: 1rem;
    width: 100vw;
    max-width: 80%;
    display: flex;
    max-height: 18rem;
    overflow-x: auto;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.worksContainer::-webkit-scrollbar{
    width: 0;
}

.worksImage {
    object-fit: cover;
    width: 18rem;
    height: auto;
}

.projectContainer {
    min-width: 18rem;
    max-width: 18rem;
    margin: 1rem 0.75rem;
    overflow: auto;
}

.projectContainer::-webkit-scrollbar{
    width: 0;
}

.worksDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.goToBtn {
    background: white;
    border: none;
    border-radius: 2rem;
    padding: 0.25rem 0.5rem;
    color: black;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
}

.goToBtn:hover {
    background: gold;
    cursor: pointer;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}