.Navbar{
    backdrop-filter: blur(10px);
    height: 5rem;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
}

.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover{
    color: gold;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid gold;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}

.desktopMenuBtn{
    border: none;
    background: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
}

.desktopMenuBtn:hover{
    background: gold;
    cursor: pointer;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.desktopMenuImg {
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem 0.5rem;
}

.active{
    color: gold;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid gold;
}

.mobMenu {
    display: none;
    object-fit: cover;
    height: 1.8rem;
}

.navMenu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40,40,40);
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.listItem {
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30,30,30);
}

@media screen and (max-width:720px) {
    .mobMenu{
        display: flex;
    }
    .desktopMenu {
        display: none;
    }
    .desktopMenuBtn{
        display: none;
    }
}